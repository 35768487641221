


















import { Vue, Component, Prop } from 'vue-property-decorator';
import Title from "@/components/Title.vue";
import ContentBox from "@/components/ContentBox.vue";
import VueToggles from 'vue-toggles';

@Component({
  components: { Title, ContentBox, VueToggles }
})
export default class Application extends Vue {

  @Prop({ type: Object, default: () => ({}) })
  record: Record<any, any>;

  @Prop({ type: Boolean, default: false })
  disabled: boolean;

  get title(): string {
    return `${this.record.applicationName}:${this.record.configName}:${this.record.envName}`;
  }
}

