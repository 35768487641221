

















import {get} from 'lodash';
import {Vue, Component} from 'vue-property-decorator';
import Title from "@/components/Title.vue";
import ContentBox from '@/components/ContentBox.vue';
import Version from "@/components/Version.vue";
import Application from "@/components/Application.vue";

@Component({
  components: {Application, Version, Title, ContentBox}
})
export default class Applications extends Vue {

  get applications() {
    return get(this, '$store.state.applications.list', [])
  }
}
